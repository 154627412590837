/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code",
    ol: "ol",
    li: "li",
    ul: "ul",
    h3: "h3",
    h4: "h4",
    br: "br",
    span: "span",
    div: "div"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-6-wait-for-it"
  }, "Day 6: Wait For It"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2023/day/6"
  }, "https://adventofcode.com/2023/day/6")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2023/solutions/src/day_06.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "A ferry brings you to a boat race.\nThe prize for winning the race is a trip to desert island, you want to go there, because you heard some sand is needed to fix the snow issue.\nA island named ", React.createElement(_components.strong, null, "Desert Island"), " will surely have a lot of that ", React.createElement(_components.a, {
    href: "https://youtu.be/2tLf1JO5bvE?si=rneA0KA-ari18Ct8"
  }, "annoying, course, stuff"), "."), "\n", React.createElement(_components.p, null, "This race is slightly unusual, the amount of ", React.createElement(_components.strong, null, "time everyone gets is fixed"), ".\nWhoever gets the ", React.createElement(_components.strong, null, "furthest in that time wins"), "."), "\n", React.createElement(_components.p, null, "Today’s input is a report of previous races."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "Time:      7  15   30\nDistance:  9  40  200\n")), "\n", React.createElement(_components.p, null, "It maps time-limits of races to the current furthest distance."), "\n", React.createElement(_components.p, null, "This document describes three races:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Race 1 lasts 7 milliseconds. The record distance in this race is 9 millimeters."), "\n", React.createElement(_components.li, null, "Race 2 lasts 15 milliseconds. The record distance in this race is 40 millimeters."), "\n", React.createElement(_components.li, null, "Race 3 lasts 30 milliseconds. The record distance in this race is 200 millimeters."), "\n"), "\n", React.createElement(_components.p, null, "The boats are toy boats."), "\n", React.createElement(_components.p, null, "Before they start moving, you have to charge them by holding a button.\nThe longer you charge them, the larger the boat’s speed."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The moment you start charging, the time starts"), "\n", React.createElement(_components.li, null, "The moment you stop charging, the boat starts moving (and doesn’t lose speed, apparently they were made by a physics teacher elf that assumed a frictionless vaccuum)"), "\n"), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "For each race, determine the amount of ways you can beat the current record."), "\n", React.createElement(_components.p, null, "For the first race in the example (that lasts 7 milliseconds), there are 4 ways to beat the record of 9 millimeters:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Hold the button for 2 milliseconds at the start of the race."), "\n", React.createElement(_components.li, null, "Hold the button for 3 milliseconds at the start of the race."), "\n", React.createElement(_components.li, null, "Hold the button for 4 milliseconds at the start of the race."), "\n", React.createElement(_components.li, null, "Hold the button for 5 milliseconds at the start of the race."), "\n"), "\n", React.createElement(_components.p, null, "The question asks you to multiply the number of ways you can win each race."), "\n", React.createElement(_components.h3, {
    id: "parsing"
  }, "Parsing"), "\n", React.createElement(_components.p, null, "Getting 2 lists of numbers, and combining them."), "\n", React.createElement(_components.p, null, "For my own convenience, I used a ", React.createElement(_components.code, null, "Race"), " structure to represent each race, so I don’t confuse myself by accessing things by index."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "struct Race {\n    time: u32,\n    dist: u32,\n}\n")), "\n", React.createElement(_components.p, null, "I chose to create a list of times and a list of distances, then zip those 2 lists together to get a list of races."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let (time, dist) = input.split_once(\"\\n\").unwrap();\nlet time = time\n    .strip_prefix(\"Time: \")\n    .unwrap()\n    .split_whitespace()\n    .map(|s| s.parse::<u32>().unwrap());\nlet dist = dist\n    .strip_prefix(\"Distance: \")\n    .unwrap()\n    .split_whitespace()\n    .map(|s| s.parse::<u32>().unwrap());\nlet races = time.zip(dist).map(|(time, dist)| Race { time, dist });\n")), "\n", React.createElement(_components.h3, {
    id: "code"
  }, "Code"), "\n", React.createElement(_components.p, null, "The logic then loops over each race and determines how many ways I can win for each one.\nAt the end, those counts are multiplied."), "\n", React.createElement(_components.p, null, "For every race:\nI determined the distance I would travel for every possible time I held the button.\nIf the resulting final distance was greater than the current record, I won."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_06.rs"
  }, "struct Race {\n    time: u32,\n    dist: u32,\n}\n\npub fn part_1(input: &str) -> usize {\n    let (time, dist) = input.split_once(\"\\n\").unwrap();\n    let time = time\n        .strip_prefix(\"Time: \")\n        .unwrap()\n        .split_whitespace()\n        .map(|s| s.parse::<u32>().unwrap());\n    let dist = dist\n        .strip_prefix(\"Distance: \")\n        .unwrap()\n        .split_whitespace()\n        .map(|s| s.parse::<u32>().unwrap());\n    let races = time.zip(dist).map(|(time, dist)| Race { time, dist });\n\n    races\n        .map(|race| {\n            (0..=race.time)\n                .map(|elapsed| {\n                    let speed = elapsed;\n                    speed * (race.time - elapsed)\n                })\n                .filter(|&dist| dist > race.dist)\n                .count()\n        })\n        .product::<usize>()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "It turns out the input had really bad ", React.createElement(_components.a, {
    href: "https://xkcd.com/1015/"
  }, "keming"), ".\nEach line is one number."), "\n", React.createElement(_components.p, null, "The question stays the same, but this time there is only 1 race."), "\n", React.createElement(_components.h3, {
    id: "parsing-1"
  }, "Parsing"), "\n", React.createElement(_components.p, null, "For both time, and distance, I made a list of single digits.\nFor each digit I encountered, I concatenate it to the end of the current number with math."), "\n", React.createElement(_components.p, null, "In the example input:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "Time:      7  15   30\nDistance:  9  40  200\n")), "\n", React.createElement(_components.p, null, "For time:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The first digit is 7, the current number becomes 7"), "\n", React.createElement(_components.li, null, "The second digit is 1, the current number becomes 71"), "\n", React.createElement(_components.li, null, "The third digit is 5, the current number becomes 715"), "\n", React.createElement(_components.li, null, "The fourth digit is 3, the current number becomes 7153"), "\n", React.createElement(_components.li, null, "The final digit is 0, the current number becomes 71530"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let (time, dist) = input.split_once(\"\\n\").unwrap();\nlet race_time = time\n    .strip_prefix(\"Time: \")\n    .unwrap()\n    .chars()\n    .filter_map(|c| c.to_digit(10))\n    .fold(0u64, |curr, digit| curr * 10 + digit as u64);\nlet race_dist = dist\n    .strip_prefix(\"Distance: \")\n    .unwrap()\n    .chars()\n    .filter_map(|c| c.to_digit(10))\n    .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n")), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "These number are BIG, really big.\nThat’s why I stored them as 64 bit integers.")), "\n", React.createElement(_components.h3, {
    id: "option-1-brute-force"
  }, "Option 1: brute-force"), "\n", React.createElement(_components.p, null, "The code from part 1 can largely be reused."), "\n", React.createElement(_components.p, null, "And joy, oh joy, I could even ", React.createElement(_components.strong, null, "remove"), " some code!"), "\n", React.createElement(_components.h4, {
    id: "code-1"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_06.rs"
  }, "pub fn part_2(input: &str) -> usize {\n    let (time, dist) = input.split_once(\"\\n\").unwrap();\n    let race_time = time\n        .strip_prefix(\"Time: \")\n        .unwrap()\n        .chars()\n        .filter_map(|c| c.to_digit(10))\n        .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n    let race_dist = dist\n        .strip_prefix(\"Distance: \")\n        .unwrap()\n        .chars()\n        .filter_map(|c| c.to_digit(10))\n        .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n\n    (0..=race_time)\n        .map(|elapsed| {\n            let speed = elapsed;\n            speed * (race_time - elapsed)\n        })\n        .filter(|&dist| dist > race_dist)\n        .count()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-math"
  }, "Option 2: math"), "\n", React.createElement(_components.p, null, "This question was describing a quadratic graph.\nThe answer is the difference between the two intersections with the y-axis on that graph."), "\n", React.createElement(_components.p, null, "Time to dust of the highschool math!"), "\n", React.createElement(_components.p, null, "The equation we used to determine the distance we travel:", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "x * (time - x) = dist")), "\n", React.createElement(_components.p, null, "Written differently, that looks more familiar:", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "y = x^2 - time*x + dist")), "\n", React.createElement(_components.p, null, "To find the 2 intersections of the second degree function with the x-axis, we set y to 0 and solve for x:", React.createElement(_components.br), "\n", React.createElement(_components.span, {
    className: "math math-inline"
  }, "0 = x^2 - time*x + dist")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://youtu.be/i7idZfS8t8w?si=FHnYI6--op32fkdk"
  }, "A refresher on the quadratic equation from Khan Academy"), "."), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, "x = \\frac {-b \\pm \\sqrt{b^2 - 4ac}} {2a}"), "\n", React.createElement(_components.p, null, "The answer is the difference between the two points where the graph intersects with the x-axis."), "\n", React.createElement(_components.p, null, "I can only hold the button for an integer amount, so I need to round up the lowest value, and round down the highest value."), "\n", React.createElement(_components.p, null, "Then add 1 to the difference between those 2, because I want to include that last point where I can win the race."), "\n", React.createElement(_components.h4, {
    id: "code-2"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_06.rs"
  }, "pub fn part_two_math(input: &str) -> u32 {\n    let (time, dist) = input.split_once(\"\\n\").unwrap();\n    let race_time = time\n        .strip_prefix(\"Time: \")\n        .unwrap()\n        .chars()\n        .filter_map(|c| c.to_digit(10))\n        .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n    let race_dist = dist\n        .strip_prefix(\"Distance: \")\n        .unwrap()\n        .chars()\n        .filter_map(|c| c.to_digit(10))\n        .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n\n    // time to dust off the math I learned in high school, a refresher: https://youtu.be/i7idZfS8t8w?si=FHnYI6--op32fkdk\n    // x * (time - x) = dist\n    // y = x^2 - time*x + dist\n    // find the 2 intersections of the second degree function with the x-axis\n    // so we set y to 0 and solve for x\n    // 0 = x^2 - time*x + dist\n\n    // The answer is the difference between the two points the graph intersects with the x-axis\n    // x1 = (-b - SQRT(b^2 - 4*a*c)) / (2 * a)\n    // x2 = (-b + SQRT(b^2 - 4*a*c)) / (2 * a)\n    let a = 1.0;\n    let b = 0.0 - race_time as f64;\n    let c = race_dist as f64;\n\n    let x1 = ((0.0 - b) - (b.powf(2.0) - (4.0 * a * c)).sqrt()) / (2.0 * a);\n    let x2 = ((0.0 - b) + (b.powf(2.0) - (4.0 * a * c)).sqrt()) / (2.0 * a);\n\n    // Because you can only hold the button for integer amounts, round up the lower value and round down the upper value\n    // And add 1 since |x2 - x1| gives you the amount from x1 to below x2 but we want to include x2.\n    let lower_bound = x1.ceil() as u32;\n    let upper_bound = x2.floor() as u32 + 1;\n\n    upper_bound - lower_bound\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_06.rs",
    numberLines: true
  }, "struct Race {\n    time: u32,\n    dist: u32,\n}\n\npub fn part_1(input: &str) -> usize {\n    let (time, dist) = input.split_once(\"\\n\").unwrap();\n    let time = time\n        .strip_prefix(\"Time: \")\n        .unwrap()\n        .split_whitespace()\n        .map(|s| s.parse::<u32>().unwrap());\n    let dist = dist\n        .strip_prefix(\"Distance: \")\n        .unwrap()\n        .split_whitespace()\n        .map(|s| s.parse::<u32>().unwrap());\n    let races = time.zip(dist).map(|(time, dist)| Race { time, dist });\n\n    races\n        .map(|race| {\n            (0..=race.time)\n                .map(|elapsed| {\n                    let speed = elapsed;\n                    speed * (race.time - elapsed)\n                })\n                .filter(|&dist| dist > race.dist)\n                .count()\n        })\n        .product::<usize>()\n}\n\npub fn part_2(input: &str) -> u32 {\n    let (time, dist) = input.split_once(\"\\n\").unwrap();\n    let race_time = time\n        .strip_prefix(\"Time: \")\n        .unwrap()\n        .chars()\n        .filter_map(|c| c.to_digit(10))\n        .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n    let race_dist = dist\n        .strip_prefix(\"Distance: \")\n        .unwrap()\n        .chars()\n        .filter_map(|c| c.to_digit(10))\n        .fold(0u64, |curr, digit| curr * 10 + digit as u64);\n\n    let a = 1.0;\n    let b = 0.0 - race_time as f64;\n    let c = race_dist as f64;\n\n    let x1 = ((0.0 - b) - (b.powf(2.0) - (4.0 * a * c)).sqrt()) / (2.0 * a);\n    let x2 = ((0.0 - b) + (b.powf(2.0) - (4.0 * a * c)).sqrt()) / (2.0 * a);\n\n    let lower_bound = x1.ceil() as u32;\n    let upper_bound = x2.floor() as u32 + 1;\n\n    upper_bound - lower_bound\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
